import React from 'react';
import PropTypes from "prop-types"
// import Logo from '../../components/logo/Logo';
import Logo from '../../logo/Logo';
import PharmaEdgeMobileNavMenu from './PharmaEdgeSub-component/PharmaEdgeMobileNavMenu';

const PharmaEdgeMobileMenu = ({show, onClose}) => {
    return (
        <div id="site-main-mobile-menu" className={`site-main-mobile-menu ${show ? "active": ""}`}>
        <div className="site-main-mobile-menu-inner">
            <div className="mobile-menu-header">
                <div className="mobile-menu-logo">
                    <Logo image={process.env.PUBLIC_URL + "/images/logo/logo.svg"}/>
                </div>
                <div className="mobile-menu-close">
                    <button className="toggle" onClick={onClose}>
                        <i className="icon-top"></i>
                        <i className="icon-bottom"></i>
                    </button>
                </div>
            </div>
            <div className="mobile-menu-content">
                <PharmaEdgeMobileNavMenu />
            </div>
        </div>
    </div>
    )
}

PharmaEdgeMobileMenu.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func
}

export default PharmaEdgeMobileMenu;
